<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12 col-lg-3 c g">
      <div class="card card-body">
        <h2>
          {{ data.users }}
        </h2>
        <h5>
          عدد المستخدمين
        </h5>
      </div>
    </div>
    <div class="col-12 col-lg-3 c g">
      <div class="card card-body">
        <h2>
          {{ data.busses }}
        </h2>
        <h5>
          عدد الباصات
        </h5>
      </div>
    </div>
    <div class="col-12 col-lg-3 c g">
      <div class="card card-body">
        <h2>
          {{ data.students }}
        </h2>
        <h5>
          عدد الطلاب
        </h5>
      </div>
    </div>
    <div class="col-12 col-lg-3 c g">
      <div class="card card-body">
        <h2>
          {{ data.orders }}
        </h2>
        <h5>
          الطلبات الناجحة
        </h5>
      </div>
    </div>
  </b-overlay>
</template>

<script>
delete Icon.Default.prototype._getIconUrl;
import { LMap, LTileLayer, LMarker, LIcon, LPolyline } from "vue2-leaflet";
import { latLng, icon, Icon } from "leaflet";
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
import {
  BCard,
  BCardText,
  BLink,
  BOverlay,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BImg,
  BMediaBody,
  VBModal,
} from "bootstrap-vue";
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    Swiper,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPolyline,
    SwiperSlide,
    BCard,
    BCardText,
    BLink,
    BImg,
    BOverlay,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      loading: true,
      data: {}
    };
  },
  created() {
    var g = this;
    g.getStats()
  },
  methods: {
    getStats() {
      var g = this;
      $.post(api + "/admin/stats", {
        jwt: this.user.jwt,
      })
        .then(function (r) {
          r = r;
          g.data = r.response;
          g.loading = false;
        })
        .catch(function (e) {
          g.allowed = false;
          alert("تعذر الإتصال بالخادم..");
        });
    },
  },
};
</script>

<style scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.5/leaflet.css";
#progress-wrp {
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
  display: none;
}
.badge {
  border-left: 2px solid #0099cc;
}
#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: greenyellow;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
</style><style lang="scss">
.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}
</style>